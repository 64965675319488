<template lang='pug'>
	b-alert.alert-rainbow(show :class='classAlert')
		.d-flex.justify-content-between.align-items-center.flex-column.flex-md-row(v-if='btn')
			.d-flex-inline.d-md-flex.align-items-center
				span.badge-text(v-if="badge" :class="isMobile ? 'mr-2' : 'mr-3'") {{ badge }}
				span(:class="isMobile ? 'b3' : 'b1'" v-html='text' @click='textClick')
			.btn-alert.mt-3.mt-md-0.ml-md-2(@click='btn.fn')
				span {{ btn.title }}
		.media.align-items-center(v-else)
			b-badge.badge-xs.mr-2(:variant='variant' :class='classBadge') !
			.media-body {{ text }}
</template>

<script>
export default {
	name: 'PwAlert',
	props: {
		btn: {
			type: Object,
			default: {value: null, title: null}
		},
		variant: {
			type: String,
			default: null
		},
		text: {
			type: String,
			default: null
		},
		textFn: {
			type: Function,
			default: null
		},
		badge: {
			type: String,
			default: ''
		}
	},
	methods: {
		textClick () {
			if ( this.textFn && typeof this.textFn == 'function' ) this.textFn();
		}
	},
	computed : {
		classBadge () {
			return `badge-${this.variant}`
		},
		classAlert () {
			return `alert-${this.variant}`
		}
	}
}
</script>
