<template lang='pug'>
	.card-basic.advantages(v-if="advantagesLeft.length > 0 || advantagesRight.length > 0" :class="advantagesClass")
		h2.bold.advantages__title {{ title }}
		p.b3.mb-3.text-grey(v-if="advantagesClass == 'advantages--resources'") {{ $t('resources.noResources.textTop') }}
		ul.d-flex.align-items-start.flex-wrap.list-unstyled.m-0.advantages__list
			.position-relative.advantages__column(v-if="advantagesLeft.length > 0")
				li.d-flex.align-items-center.advantages__item(v-for="(item, i) in advantagesLeft" :key="`advantage-${i}`")
					.d-flex.justify-content-center.align-items-center.rounded-circle.p-2.mr-4.position-relative.z-index-1.advantages__icon
						img(src="/assets/img/icons/check.svg")
					p.m-0.b1.advantages__text(v-html="item.text")
			.position-relative.advantages__column(v-if="advantagesRight.length > 0")
				li.d-flex.align-items-center.advantages__item(v-for="(item, i) in advantagesRight" :key="`advantage-${i}`")
					.d-flex.justify-content-center.align-items-center.rounded-circle.p-2.mr-4.position-relative.z-index-1.advantages__icon
						img(src="/assets/img/icons/check.svg")
					p.m-0.b1.advantages__text(v-html="item.text")
		p.b3.text-grey.m-0(v-if="advantagesClass == 'advantages--resources'") {{ $t('resources.noResources.textBottom') }}
</template>

<script>
export default {
	name: 'Advantages',
	props: {
		title: {
			default : '',
			type : String
		},
		advantagesLeft: {
			default : () => [],
			type : Array
		},
		advantagesRight: {
			default : () => [],
			type : Array
		},
		advantagesClass: {
			default : '',
			type : String
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.advantages {
	padding: 35px 20px 30px 20px;

	@include respond-below(sm) {
		padding: 20px 16px 20px 16px;
	}

	&__title {
		margin-bottom: 20px !important;

		@include respond-below(sm) {
			margin-bottom: 5px !important;
		}
	}

	&__item {
		margin-bottom: 16px;

		@include respond-below(sm){
			margin-bottom: 20px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__column {
		width: 45.6%;
		margin-right: 15px;

		&:last-of-type {
			margin-right: 0;
		}

		@include respond-below(sm) {
			width: 100%;
			margin-right: 0;
		}

		&::after {
			content: "";
			position: absolute;
			top: 18px;
			left: 17px;
			width: 1px;
			height: calc(100% - 55px);
			background-image: url('/assets/img/icons/line.svg');
		}
	}

	&__list {
		margin-bottom: 15px !important;

		@include respond-below(sm){
			margin-top: 20px !important;
			margin-bottom: 19px !important;
		}
	}

	&__icon {
		z-index: 1;
		max-width: 35px;
		min-width: 35px;
		max-height: 35px;
		min-height: 35px;
		background-color: var(--foreground-back);
		box-shadow: var(--box-shadow-icon);

		@include respond-below(sm){
			margin-right: 12px !important;
		}
	}

	&__text {
		@include respond-below(sm){
			font-size: 14px !important;
			line-height: 20px !important;
		}
	}

	&--resources {
		max-width: 935px;
	}

	&--plans {
		padding-top: 42px;
		padding-bottom: 28px;

		@include respond-below(sm){
			padding: 20px 23px 20px 15px;
			margin-top: 4px;
		}

		.advantages {
			&__title {
				margin-bottom: 34px !important;

				@include respond-below(sm){
					margin-bottom: 0px !important;
				}
			}

			&__column {
				&:last-of-type {
					.advantages__item {
						align-items: flex-start !important;

						@include respond-below(sm) {
							align-items: center !important;
						}
					}
				}

				@include respond-below(sm) {
					margin-bottom: 20px;

					&:last-of-type {
						margin-bottom: 0;

						&:after {
							top: -52px;
							height: calc(100% + 32px);
						}
					}
				}
			}

			&__list {
				@include respond-below(sm){
					margin-top: 16px !important;
					margin-bottom: 0 !important;
				}
			}

			&__item {
				margin-bottom: 25px;

				@include respond-below(sm){
					margin-bottom: 20px;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			&__text {
				max-width: 270px;

				@include respond-below(sm) {
					max-width: 100%;
				}
			}
		}
	}

	&--promo-codes {
		.advantages {
			&__text {
				max-width: 305px;
			}
		}
	}

	&--comebacker {
		.advantages {
			&__column {
				&:last-of-type {
					.advantages__text {
						max-width: 350px;
					}

					.advantages__item {
						&:last-of-type {
							align-items: center !important;
						}
					}
				}
			}
		}
	}
}
</style>
